import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const CookiesPage = () => {

    const blocks = [
        {
            content: 'At Playdrop.gg, we use cookies to provide you with a better experience on our website. This policy explains what cookies are, how we use them, and how you can control them.',
        },
        {
            content: 'Cookies are small files that are placed on your device when you visit our website. They allow us to remember your preferences, login information, and other information to enhance your experience.',
            title: 'What are Cookies',
        },
        {
            content: 'We use two types of cookies on our website: session cookies and persistent cookies. Session cookies are deleted when you close your browser, while persistent cookies remain on your device until they expire or you delete them.',
            title: 'Types of Cookies',
        },
        {
            content: 'We use cookies to remember your login information, personalize your experience, and analyze how our website is used. We also use third-party cookies, such as Google Analytics, to help us understand how visitors use our website.',
            title: 'How We Use Cookies',
        },
        {
            content: 'We may update this policy from time to time. The revised policy will be posted on our website with an updated date.',
            title: 'Changes to this Cookie Policy'
        },
        /*{
            action: {
                label: 'support@playdrop.gg',
                onClick: () => window.open('mailto:support@playdrop.gg', '_blank'),
                type: 'inline',
            },
            content: 'If you have any questions or concerns about our privacy practices, please contact us at:',
        },*/
        {
            action: {
                label: 'contact us on our Discord channel',
                onClick: () => window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank'),
                type: 'inline',
            },
            content: [
                'If you have any questions or concerns about our privacy practices, please',
            ],
        },
    ];
    const title = "Cookie Policy";

    return (
        <Layout 
            title="Cookie Policy"
        >
            <SimplePage
                blocks={blocks}
                title={title}
            />
        </Layout>
    )
}

export default CookiesPage;